.logo {
    /*border: red 1px solid;*/
    margin: 5px;
    padding: 5px;
    display: block;
    height: 75px;
}

.site-layout .site-layout-background {
    background: #fff;
}